import React from "react"
import ART from './assets/art.jpg'
import LOGO_WHITE from './assets/logo-white.png'
import BACK2 from './assets/back2.jpg'
import BACK3 from './assets/back3.jpg'
import BACK4 from './assets/back4.jpg'
import BACK5 from './assets/back5.jpg'
import TEZOS from './assets/tezos.png'
import ART_TEZOS from './assets/art_tezos.jpg'
import ART_GALERIECHARLOT from './assets/art_galeriecharlot.jpg'
import BALLONDOR from './assets/ballondor.jpg'
import COLLABORATION from './assets/collaboration.jpg'
import LEQUIPE from './assets/lequipe.png'
import FFOOT from './assets/france_football.png'
import SCEME from './assets/logo-sceme@2x.png'
import BACKSCEME from './assets/back_sceme.jpg'
import { FaChevronDown } from 'react-icons/fa'

class App extends React.Component {
  constructor() {
    super()
    this.welcome = React.createRef()
    this.approach = React.createRef()
    this.tezos = React.createRef()
    this.charlot = React.createRef()
    this.ballondor = React.createRef()
    this.contact = React.createRef()
  }

  
  scroll = (ref) =>{
    ref.current.scrollIntoView({behavior: 'smooth'})
  }

  render = () => (
    <main>
      <content ref={this.welcome}>
        <section>
          <div className="block">
            <img src={LOGO_WHITE} className="logo"/>
            <h1 className="title">Premiere.gallery</h1>
            <h2 className="tag">The best NFT gallery enabler.</h2>
            <p>Open you gallery with Premiere.gallery to offer the best experience to your creators and art lovers</p>
          </div>
          <div className="block">
            <div style={{backgroundImage: `url('${ART}')`}} className="art"></div>
          </div>
        </section>
        <div className="more" onClick={() => this.scroll(this.approach)}>
          Scroll down to discover more
          <FaChevronDown className="icon"/> 
        </div>
      </content>
      <content style={{backgroundImage: `url('${BACK3}')`}} ref={this.approach}>
        <section className="no-top">
          <h1 className="title" style={{paddingTop: "40px"}}>We collaborate together to build your perfect NFT gallery</h1>
          <div className="block" style={{display: "block", margin: "0 auto"}}>
            <h2 className="tag text-center">Efficient and simple</h2>
            <p className="text-center"># Setting up your own smart contract</p>
            <p className="text-center"># Configuring your Gallery UX</p>
            <p className="text-center"># Onboarding of your creators & royalties setup</p>
            <h3 className="text-center"># Launch of your personal Gallery powered Premiere.gallery</h3>
          </div>
        </section>
        <div className="more" onClick={() => this.scroll(this.tezos)}>
          Responsible technology
          <FaChevronDown className="icon"/> 
        </div>
      </content>
      <content style={{backgroundImage: `url('${BACK2}')`}} ref={this.tezos}>
        <section>
          <div className="block">
            <div style={{backgroundImage: `url('${ART_TEZOS}')`}} className="art"></div>
          </div>
          <div className="block">
            <h1 className="title">Built on <img src={TEZOS} className="logo" style={{display: "inline-block", margin: "0px 8px", verticalAlign: "bottom", width: "240px"}}/></h1>
            <p>
              Tezos is an open-source platform that addresses key barriers facing blockchain adoption for assets and applications backed by a global community of validators, 
              researchers, and builders. By design, Tezos embraces long-term upgradability, open participation, collaboration, and smart contract safety.
            </p>
            <p><strong>Coming soon on Polygon & Ethereum...</strong></p>
          </div>
        </section>
        <div className="more" onClick={() => this.scroll(this.charlot)}>
          Galerie Charlot x Premiere.gallery
          <FaChevronDown className="icon"/> 
        </div>
      </content>
      <content style={{backgroundImage: `url('${BACK4}')`}} ref={this.charlot}>
        <section>
            <div style={{backgroundImage: `url('${ART_GALERIECHARLOT}')`}} className="max_art pointer" onClick={() => window.open('https://charlotnft.premiere.gallery')}></div>
            <h1 className="title pointer" style={{color: "#0dff00", textShadow: "2px 2px rgba(54,54,54, 0.6)"}} onClick={() => window.open('https://charlotnft.premiere.gallery')}>Galerie Charlot x Premiere.gallery</h1>
        </section>
        <div className="more" style={{color: "#111"}} onClick={() => this.scroll(this.ballondor)}>
          2022 Ballon d'Or® collection
          <FaChevronDown className="icon"/> 
        </div>
      </content>
      <content style={{backgroundImage: `url('${BALLONDOR}')`}} ref={this.ballondor}>
        <section>
            <img src={LEQUIPE} className="partner1" alt="Logo L'Equipe" onClick={() => window.open('https://lequipe.fr')}/>
            <img src={FFOOT} className="partner2" alt="Logo France Football" onClick={() => window.open('https://www.francefootball.fr')}/>
            <video src="https://nftballondor.s3.eu-west-3.amazonaws.com/TruePyrite-2.mp4" autoPlay loop muted playsInline></video>
            <h1 className="title pointer" style={{color: "#dbc657", textShadow: "2px 2px rgba(54,54,54, 0.6)"}} onClick={() => window.open('https://nft.ballondor.com')}>2022 Ballon d'Or® collection</h1>
        </section>
        <div className="more" onClick={() => this.scroll(this.contact)}>
          Contact us
          <FaChevronDown className="icon"/> 
        </div>
      </content>
      <content style={{backgroundImage: `url('${BACK5}')`}} ref={this.contact}>
        <section>
          <div className="block">
            <h1 className="title">Get in touch</h1>
            <h2 className="tag text-center pointer" onClick={() => {window.open("mailto:contact@premiere.gallery")}}>contact@premiere.gallery</h2>
            <p>Let's discuss your needs and project to setup the best possible solution for you</p>
          </div>
          <div className="block">
            <div style={{backgroundImage: `url('${COLLABORATION}')`, borderBottom: "8px solid #111"}} className="art"></div>
          </div>
        </section>
        <div className="footer">
          <div className="entry" onClick={() => this.scroll(this.welcome)}>Back on top</div>
        </div>
      </content>
      <content style={{background: "#000", height: "auto"}}>
        <section style={{paddingTop: "0"}}>
          <div className="block" style={{display: "block"}}>
            <h1 className="title" style={{cursor: "pointer"}} onClick={() => window.open("https://sceme.io")}>
              by <img src={SCEME} className="logo" style={{display: "inline-block", margin: "0 8px 10px 8px", verticalAlign: "bottom", width: "200px"}}/>
            </h1>
            <p>
              <strong>Sceme helps customers in the implementation of digital currencies & NFTs.</strong>
              <br/>
              Our solutions enable global management for decentralized applications insuring a secure and compliant infrastructure.
            </p>
            <p>
              <strong>© SCEME 2023.</strong>
              <br/>
              <a href="https://sceme.io/en/legal-mentions/" target="_blank" rel="noreferrer" style={{color: "#fff"}}>Legal notice</a>
            </p>
          </div>
        </section>
      </content>
    </main>
  );
}

export default App;
